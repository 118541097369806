import type { ApiResponseError } from '@centric-os/types';
import type { AxiosInstance } from 'axios';
import type { Store, StoreActions } from 'pinia';
import { markRaw, reactive, toRefs } from 'vue';

type PluginOptions = {
  api: AxiosInstance;
  cdlApi?: AxiosInstance;
  genericAPI?: AxiosInstance;
};

type ActionState = {
  loading: boolean;
  ready: boolean;
  errors: ApiResponseError | null;
};

export const APIPlugin = ({ api, cdlApi, genericAPI }: PluginOptions) => {
  return () => ({
    api: markRaw(api),
    cdlApi: cdlApi && markRaw(cdlApi),
    genericAPI: genericAPI && markRaw(genericAPI),
  });
};

export function useStoreAPIAction<S extends Store, AC extends keyof StoreActions<S>>(
  store: S,
  actionName: AC,
) {
  const state = reactive<ActionState>({
    loading: false,
    ready: false,
    errors: null,
  });

  store.$onAction(({ after, name, onError }) => {
    if (name === actionName) {
      state.loading = true;
      state.ready = false;
      state.errors = null;

      after(() => {
        state.loading = false;
        state.ready = true;
      });

      onError((errors) => {
        state.errors = errors;
        state.loading = false;
        state.ready = true;
      });
    }
  });

  return { ...toRefs(state) };
}
