export interface AnnouncementsResponse {
  announcements: Announcement[];
}

export interface Announcement {
  edit_history?: EditHistory[];
  app: string;
  created_at: Date;
  is_global: boolean;
  name: string;
  info: Info;
  active: boolean;
  allowed_resources: string[];
  key: string;
  id: string;
  position: number;
  last_modified: Date;
  type: string;
  author: Author;
  schedule: AnnouncementSchedule;

  // Additional properties for table columns
  createdBy?: string;
  editBy?: string;
  sites?: string;
  scheduleStart?: Date;
  scheduleEnd?: Date;

  // Additional properties for form
  other?: string;
}

export interface EditHistory {
  date: Date;
  user: Author;
}

export interface Info {
  en: AnnouncementInfo;
  fr?: AnnouncementInfo;
}

export interface AnnouncementInfo {
  title: string;
  description: string;
  sub_text: string;
  button_text: string;
  image_url?: string;
}

export interface Author {
  id: string;
  name: Name;
}

export interface Name {
  first: string;
  last: string;
}

export interface AnnouncementSchedule {
  start_datetime: Date;
  end_datetime: Date;
  variable: boolean;
  sites?: SiteAnnouncementSchedule[];
}

export interface SiteAnnouncementSchedule {
  id: string;
  start_datetime: Date;
  end_datetime: Date;
}

// Enum for column keys
export enum ColumnKeys {
  IMAGE = 'image',
  NAME = 'name',
  APP = 'app',
  TYPE = 'type',
  SITES = 'sites',
  SCHEDULE_START = 'scheduleStart',
  SCHEDULE_END = 'scheduleEnd',
  CREATED_AT = 'created_at',
  CREATED_BY = 'createdBy',
  LAST_MODIFIED = 'last_modified',
  EDIT_BY = 'editBy',
  POSITION = 'position',
  ACTIVE = 'active',
}

export enum AnnouncementViews {
  LIVE = 'live',
  PAST = 'past',
}
